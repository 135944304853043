/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
exports.onRouteUpdate = ({ location, prevLocation }) => {
  if(prevLocation && location.pathname === prevLocation.pathname) return;
  function tryExecuteMainScript() {
    setTimeout(function () {
      if (window.MainScript) {
        window.MainScript()
      } else {
        tryExecuteMainScript()
      }
    }, 10)
  }

  tryExecuteMainScript()
}
