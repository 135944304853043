// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-co-robimy-js": () => import("./../../../src/pages/co-robimy.js" /* webpackChunkName: "component---src-pages-co-robimy-js" */),
  "component---src-pages-dlabiznesu-js": () => import("./../../../src/pages/dlabiznesu.js" /* webpackChunkName: "component---src-pages-dlabiznesu-js" */),
  "component---src-pages-dokumenty-js": () => import("./../../../src/pages/dokumenty.js" /* webpackChunkName: "component---src-pages-dokumenty-js" */),
  "component---src-pages-dziekujemy-js": () => import("./../../../src/pages/dziekujemy.js" /* webpackChunkName: "component---src-pages-dziekujemy-js" */),
  "component---src-pages-en-404-js": () => import("./../../../src/pages/en/404.js" /* webpackChunkName: "component---src-pages-en-404-js" */),
  "component---src-pages-en-blog-js": () => import("./../../../src/pages/en/blog.js" /* webpackChunkName: "component---src-pages-en-blog-js" */),
  "component---src-pages-en-co-robimy-js": () => import("./../../../src/pages/en/co-robimy.js" /* webpackChunkName: "component---src-pages-en-co-robimy-js" */),
  "component---src-pages-en-dlabiznesu-js": () => import("./../../../src/pages/en/dlabiznesu.js" /* webpackChunkName: "component---src-pages-en-dlabiznesu-js" */),
  "component---src-pages-en-dokumenty-js": () => import("./../../../src/pages/en/dokumenty.js" /* webpackChunkName: "component---src-pages-en-dokumenty-js" */),
  "component---src-pages-en-dziekujemy-js": () => import("./../../../src/pages/en/dziekujemy.js" /* webpackChunkName: "component---src-pages-en-dziekujemy-js" */),
  "component---src-pages-en-faq-js": () => import("./../../../src/pages/en/faq.js" /* webpackChunkName: "component---src-pages-en-faq-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-lokalnie-js": () => import("./../../../src/pages/en/lokalnie.js" /* webpackChunkName: "component---src-pages-en-lokalnie-js" */),
  "component---src-pages-en-media-js": () => import("./../../../src/pages/en/media.js" /* webpackChunkName: "component---src-pages-en-media-js" */),
  "component---src-pages-en-o-nas-js": () => import("./../../../src/pages/en/o-nas.js" /* webpackChunkName: "component---src-pages-en-o-nas-js" */),
  "component---src-pages-en-sasa-box-js": () => import("./../../../src/pages/en/sasa-box.js" /* webpackChunkName: "component---src-pages-en-sasa-box-js" */),
  "component---src-pages-en-testujemymedykow-js": () => import("./../../../src/pages/en/testujemymedykow.js" /* webpackChunkName: "component---src-pages-en-testujemymedykow-js" */),
  "component---src-pages-en-wolontariat-js": () => import("./../../../src/pages/en/wolontariat.js" /* webpackChunkName: "component---src-pages-en-wolontariat-js" */),
  "component---src-pages-en-wspomoz-fundacje-js": () => import("./../../../src/pages/en/wspomoz-fundacje.js" /* webpackChunkName: "component---src-pages-en-wspomoz-fundacje-js" */),
  "component---src-pages-en-wystapil-blad-js": () => import("./../../../src/pages/en/wystapil-blad.js" /* webpackChunkName: "component---src-pages-en-wystapil-blad-js" */),
  "component---src-pages-en-zostan-wolontariuszem-js": () => import("./../../../src/pages/en/zostan-wolontariuszem.js" /* webpackChunkName: "component---src-pages-en-zostan-wolontariuszem-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lokalnie-js": () => import("./../../../src/pages/lokalnie.js" /* webpackChunkName: "component---src-pages-lokalnie-js" */),
  "component---src-pages-media-js": () => import("./../../../src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-sasa-box-js": () => import("./../../../src/pages/sasa-box.js" /* webpackChunkName: "component---src-pages-sasa-box-js" */),
  "component---src-pages-testujemymedykow-js": () => import("./../../../src/pages/testujemymedykow.js" /* webpackChunkName: "component---src-pages-testujemymedykow-js" */),
  "component---src-pages-wolontariat-js": () => import("./../../../src/pages/wolontariat.js" /* webpackChunkName: "component---src-pages-wolontariat-js" */),
  "component---src-pages-wspomoz-fundacje-js": () => import("./../../../src/pages/wspomoz-fundacje.js" /* webpackChunkName: "component---src-pages-wspomoz-fundacje-js" */),
  "component---src-pages-wystapil-blad-js": () => import("./../../../src/pages/wystapil-blad.js" /* webpackChunkName: "component---src-pages-wystapil-blad-js" */),
  "component---src-pages-zostan-wolontariuszem-js": () => import("./../../../src/pages/zostan-wolontariuszem.js" /* webpackChunkName: "component---src-pages-zostan-wolontariuszem-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog-category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-person-post-js": () => import("./../../../src/templates/person-post.js" /* webpackChunkName: "component---src-templates-person-post-js" */)
}

